<template>
    <b-btn class="p-0 bg-white border-0">
        <img @click="clicked" class="cursor-pointer" src="@/assets/images/close.svg" alt="close" />
    </b-btn>
</template>

<script>
export default {
    methods: {
        clicked() {
            this.$emit('close-button-clicked')
        }
    }
}
</script>

<style>

</style>